
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut
} from "firebase/auth";
import {
  collection,
  getDocs,
  query,
  where
} from "firebase/firestore";

import { app, firestore } from "../../configs/firebaseConfig";
import { notificacionError } from "../../layout/components/content/notifications";
import * as userTypes from "./userTypes";

const loadKind = async (email) => {
  const q = query(
    collection(firestore, "users"),
    where("email", "==", email)
  );
  const snaps = await getDocs(q);
  console.log("loadKind",snaps);
  if(snaps.empty){
    return [""];
  }
  const userdoc = snaps.docs[0];
  console.log("userdoc",userdoc);


  const userData = userdoc.data();
  return [userData.kind];
};

const errorHandle = (error) => {
  const errorCode = error.code;
  const errorMessage = error.message;

  notificacionError(`ERROR ${errorCode}`, `${errorMessage}`,0);

};

export const login = (email, password) => {
  return async (dispatch) => {
    dispatch({
      type: userTypes.LOADING,
    });
    const auth = getAuth(app);
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    ).catch(errorHandle);


    if (!userCredential) {
      dispatch({
        type: userTypes.LOGIN_FAILURE,
      });
    } else {
      const { user } = userCredential;

      if (user) {
        const {
          displayName,
          email,
          emailVerified,
          uid,
        } = user;
        const roles = await loadKind(email);
        dispatch({
          type: userTypes.LOGIN_SUCCESS,
          payload: {
            displayName,
            email,
            emailVerified,
            uid,
            roles,
          },
        });
      }
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    const auth = getAuth(app);
    await signOut(auth).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log("LOGOUT", { errorCode, errorMessage });
    });
    dispatch({
      type: userTypes.LOGOUT,
    });
  };
};

export const currentUser = (userData) => {
  return async (dispatch) => {
    dispatch({
      type: userTypes.LOADING,
    });

    const roles = await loadKind(userData.email);
    dispatch({
      type: userTypes.CURRENT,
      payload: {
        ...userData,
        roles,
      },
    });
  };
};

